export const stateData = {
  // haryana
  "haryana-assembly-chunav": {
    breadCrump: [
      {
        displayText: "कौन जीता-कौन हारा",
        url: "/election/haryana-assembly-chunav/results",
        keyToShow: "",
      },
      {
        displayText: "प्रमुख उम्मीदवार",
        url: "/election/haryana-assembly-chunav/key-candidates",
        keyToShow: "",
      },
      {
        displayText: "एग्जिट पोल",
        url: "/election/haryana-assembly-chunav/exit-poll",
        keyToShow: "",
      },
      {
        displayText: "विधान सभा रिजल्ट",
        url: "/election/haryana-assembly-chunav/party-wise-result",
        keyToShow: "",
      },
      {
        displayText: "शेड्यूल",
        url: "/election/haryana-assembly-chunav/schedule",
        keyToShow: "",
      },
      {
        displayText: "सामान्य प्रश्न",
        url: "/election/assembly-chunav/faq",
        keyToShow: "",
      },
    ],
    "exit-poll": {
      state: "Haryana",
      stateHindi: "हरियाणा",
      metaKey:
        "Haryana Exit Polls 2024, Haryana Assembly Election Exit Polls, Exit polls of Haryana chunav, Haryana exit polls ,Haryana exit poll results, Haryana election results, हरियाणा, विधानसभा  चुनाव, हरियाणा विधानसभा चुनाव एग्जिट पोल 2024, हरियाणा विधानसभा चुनाव एग्जिट पोल,हरियाणा विधानसभा चुनाव एग्जिट पोल news",
      tabs: [
        {
          text: "हरियाणा",
          show: true,
          showActive: "Haryana",
        },
        {
          text: "जम्मू और कश्मीर",
          show: true,
          showActive: false,
        },
      ],
      agencyName: "AgencyHaryana",
      currentYear: "2024",
      textPast: `Haryana Assembly Elections Exit Polls 2024: हरियाणा विधानसभा चुनाव  
            "2024 के लिए मतदान पूरा हो चुका है. राज्य में कांग्रेस और BJP के बीच"  
            टक्कर है. JJP और INLD भी अपने-अपने गठबंधनों के साथ मैदान में हैं. इस
            बीच सबकी नजरें एग्जिट पोल्स पर हैं. यहां जानिए एग्जिट पोल्स में
            किसकी सरकार बन रही है.`,
      textFuture: `Haryana Assembly Elections Exit Polls: हरियाणा में विधानसभा चुनाव
      2024 के लिए प्रचार जारी है. मुख्य दलों BJP, कांग्रेस, जेजेपी,
      आईएनएलडी के बीच टक्कर है. बेरोजगारी, महंगाई और किसानी जैसे मुद्दे
      प्रमुख हैं. मतगणना के बाद जल्द ही एग्जिट पोल्स जारी होंगे. तब तक आप
      साल 2019 के हरियाणा विधानसभा चुनाव के एग्जिट पोल्स पर नजर डालिए.`,
      assemblyPollData:
        "https://elections.mobiletak.com/jsonfiles/exitpolls/assemblyPollData.json",
    },
    "key-candidates": {
      dataUrl:
        "https://electionresults.intoday.in/elections/2024/assembly-elections/json/ei-dashboard/2024/key-candidate/haryana-key-candidate.json",

      // this name is also used inside the code for the logic so name as per the api
      //this needs to be same as the STATE variable that comes in the data from the api key-candidates
      stateName: "Haryana",
      stateNameHi: "हरियाणा",
      electionYear: "2024",
      textPara: `Haryana Assembly Election 2024 Key-Candidate List: हरियाणा
                  विधानसभा चुनाव में एक तरफ अलग-अलग पार्टियों के बीच टक्कर है,
                  वहीं दूसरी तरफ कई बड़े चेहरों की प्रतिष्ठा भी दांव पर है. कौन
                  से बड़े चेहरे कहां से लड़ रहे हैं चुनाव? जमीन पर कैसे बन-बिगड़
                  रहे हैं समीकरण? क्या होने जा रहा है कोई बड़ा उलटफेर? नीचे दी
                  गई लिस्ट में जानिए पूरा हाल.`,
      tabs: [
        {
          text: "हरियाणा",
          keyToShow: "",
          showActive: true,
          url: "/election/haryana-assembly-chunav/key-candidates",
        },
        {
          text: "जम्मू और कश्मीर",
          keyToShow: "JKkeyCandidate",
          showActive: false,
          url: "/election/jammu-kashmir-assembly-chunav/key-candidates",
        },
      ],
    },
    "party-wise-result": {
      state: "Haryana",
      stateHindi: "हरियाणा",
      currentYear: "2024",
      dataUrl:
        "https://elections.mobiletak.com/jsonfiles/exitpolls/assemblyResultData.json",
      dataKey: "partiesDataHR",
      metaKey:
        "haryana assembly election 2024 results, party-wise results haryana assembly election result 2024, haryana vidhan sabha election 2024, haryana election party-wise seats 2024, haryana 2024 political party performance, election results 2024 haryana state, haryana vidhan sabha party-wise results 2024, हरियाणा विधानसभा चुनाव परिणाम 2024, हरियाणा विधानसभा चुनाव 2024 पार्टी वाइज चुनाव परिणाम",
      currentYearPara: `Haryana Assembly Elections Results: 2024 के विधानसभा चुनाव में किसका
          चलेगा सिक्का? किस विधानसभा सीट पर होगी किसकी जीत? इसकी जानकारी मिलेगी
          8 अक्टूबर 2024 को. तब तक देखिए 2019 हरियाणा विधानसभा चुनाव के नतीजे
          ताकि आपको मिले पूरी और सही तस्वीर.`,
      otherYearPara: ` Haryana Assembly Elections Results 2024: इस बार के हरियाणा के विधानसभा चुनाव में चलेगा किसका सिक्का? BJP की फिर से बनेगी सरकार या कांग्रेस मार ले जाएगी बाजी? JJP और INLD के गठबंधनों का क्या है हाल? सबसे सटीक और तेज नतीजों के लिए बने रहिए दी लल्लनटॉप के साथ. नीचे दी गई लिस्ट में देखिए किस पार्टी को मिली कितनी सीटें`,
      tabs: [
        {
          text: "हरियाणा",
          keyToShow: "",
          showActive: true,
          url: "/election/haryana-assembly-chunav/party-wise-result",
        },
        {
          text: "जम्मू और कश्मीर",
          keyToShow: "JKresultpage",
          showActive: false,
          url: "/election/jammu-kashmir-assembly-chunav/party-wise-result",
        },
      ],
    },
    schedule: {
      state: "Haryana",
      stateHindi: "हरियाणा",
      electionYear: "2024",
      metaKey:
        "Haryana Assembly Election Schedule,Haryana Assembly Election Schedule,Haryana Assembly Election 2024,Haryana विधान सभा चुनाव 2024,हरियाणा विधान सभा चुनाव  2024,हरियाणा चुनाव अनुसूची 2024",

      tabs: [
        {
          text: "हरियाणा",
          keyToShow: "",
          showActive: true,
          url: "/election/haryana-assembly-chunav/schedule",
        },
        {
          text: "जम्मू और कश्मीर",
          keyToShow: "",
          showActive: false,
          url: "/election/jammu-kashmir-assembly-chunav/schedule",
        },
      ],
      tables: [
        {
          heading: "हरियाणा",
          phase: "फेज 1",
          on: "5 अक्टूबर 2024",
          result: "8 अक्टूबर 2024",
        },
      ],
    },
    result: {
      state: "Haryana",
      stateHindi: "हरियाणा",
      currentYear: "2024",
      tabs: [
        {
          text: "हरियाणा",
          keyToShow: "",
          showActive: true,
          url: "/election/haryana-assembly-chunav/results",
        },
        {
          text: "जम्मू और कश्मीर",
          keyToShow: "JK_KaunJeeta",
          showActive: false,
          url: "/election/jammu-kashmir-assembly-chunav/results",
        },
      ],
      currentYearPara: `Haryana Assembly Elections Results 2024: इस बार के हरियाणा के
      विधानसभा चुनाव में चलेगा किसका सिक्का? BJP की फिर से बनेगी सरकार या
      कांग्रेस मार ले जाएगी बाजी? JJP और INLD के गठबंधनों का क्या है हाल?
      सबसे सटीक और तेज नतीजों के लिए बने रहिए दी लल्लनटॉप के साथ. नीचे दी
      गई लिस्ट में मिलेगा एक-एक सीट का हाल. अपनी सीट का नाम सर्च कीजिए और
      जानिए ताजा चुनाव नतीजे.`,
      otherYearPara: `Haryana Assembly Elections Results: 2024 के विधानसभा चुनाव में किसका
      चलेगा सिक्का? किस विधानसभा सीट पर होगी किसकी जीत? इसकी जानकारी
      मिलेगी 8 अक्टूबर 2024 को. तब तक देखिए 2019 हरियाणा विधानसभा चुनाव के
      नतीजे ताकि आपको मिले पूरी और सही तस्वीर. नीचे दिए गए लिस्ट में सीट
      के नाम पर क्लिक कर जाने उस सीट का लेखा-जोखा. आप सर्च भी कर सकते हैं.`,
      prevDataUrl:
        "https://akm-img-a-in.tosshub.com/sites/elections/2024/assembly/json/state-master/haryana-2019-all-consituency.json",
      currentDataUrl:
        "https://electionresults.intoday.in/elections/2024/assembly-elections/json/state/haryana-all-consituency.json",
      constituency: {
        //this is used in url
        "state": "haryana",
        "stateHindi": "हरियाणा",
        "url": "https://akm-img-a-in.tosshub.com/sites/elections/2024/assembly/json/constituency/haryana/",
        "url3": "https://electionresults.intoday.in/elections/2024/assembly-elections/json/constituency-tak/",
        "url2": "https://akm-img-a-in.tosshub.com/sites/elections/2024/assembly/json/state-master/haryana-state-master.json",
        tabs:
          [
            {
              text: "हरियाणा",
              keyToShow: "",
              showActive: true,
              url: "/election/haryana-assembly-chunav/results"
            },
            {
              text: "जम्मू और कश्मीर",
              keyToShow: "JKresultpage",
              showActive: false,
              url: "/election/jammu-kashmir-assembly-chunav/results"
            }
          ]
      }
    },
    "masterConfigKey": "vidhansabha_election"
  },

  //jammu
  "jammu-kashmir-assembly-chunav": {
    breadCrump: [
      {
        displayText: "कौन जीता-कौन हारा",
        url: "/election/jammu-kashmir-assembly-chunav/results",
        keyToShow: "JK_KaunJeeta",
      },
      {
        displayText: "प्रमुख उम्मीदवार",
        url: "/election/jammu-kashmir-assembly-chunav/key-candidates",
        keyToShow: "JKkeyCandidate",
      },
      {
        displayText: "एग्जिट पोल",
        url: "/election/jammu-kashmir-assembly-chunav/exit-poll",
        keyToShow: "JKExitPollPage",
      },
      {
        displayText: "विधान सभा रिजल्ट",
        url: "/election/jammu-kashmir-assembly-chunav/party-wise-result",
        keyToShow: "JKresultpage",
      },
      {
        displayText: "शेड्यूल",
        url: "/election/jammu-kashmir-assembly-chunav/schedule",
        keyToShow: "",
      },
      {
        displayText: "सामान्य प्रश्न",
        url: "/election/assembly-chunav/faq",
        keyToShow: "",
      },
    ],
    "exit-poll": {
      state: "Jammu and Kashmir",
      stateHindi: "जम्मू और कश्मी",
      agencyName: "AgencyJK",
      metaKay:
        "Jammu and Kashmir Exit Polls 2024, Jammu and Kashmir Assembly Election Exit Polls, Exit polls of Jammu and Kashmir chunav, Jammu and Kashmir exit polls ,Jammu and Kashmir exit poll results, Jammu and Kashmir election results, जम्मू और कश्मीर, विधानसभा  चुनाव, जम्मू और कश्मीर विधानसभा चुनाव एग्जिट पोल 2024, जम्मू और कश्मीर विधानसभा चुनाव एग्जिट पोल,जम्मू और कश्मीर विधानसभा चुनाव एग्जिट पोल news",

      tabs: [
        {
          text: "हरियाणा",
          show: true,
          showActive: true,
        },
        {
          text: "जम्मू और कश्मीर",
          show: true,
          showActive: false,
        },
      ],
      textFuture:
        "जम्मू-कश्मीर विधानसभा चुनाव 2024 के लिए अंतिम चरण का मतदान 1 अक्टूबर को होगा, जिसके बाद एग्जिट पोल के आंकड़े जारी किए जाएंगे। पिछला विधानसभा चुनाव 2014 में हुआ था, जिसमें सी वोटर एग्जिट पोल ने भारतीय जनता पार्टी (बीजेपी) के लिए 27-33, कांग्रेस के लिए 4-10, नेशनल कॉन्फ्रेंस के लिए 8-14, और पीडीपी (जम्मू-कश्मीर पीपुल्स डेमोक्रेटिक पार्टी) के लिए 32-38 सीटों का अनुमान लगाया था, जबकि अन्य दलों को 2-8 सीटें मिलने की संभावना जताई गई थी। परिणामों के अनुसार, पीडीपी ने सबसे ज्यादा 28 सीटें जीतीं, जबकि बीजेपी को 25 सीटें मिलीं। नेशनल कॉन्फ्रेंस ने 15 और कांग्रेस ने 12 सीटें प्राप्त कीं।",
      textPast:
        "Jammu Kashmir Assembly Elections Exit Polls 2024: जम्मू-कश्मीर विधानसभा चुनाव 2024 के लिए मतदान पूरा हो चुका है. केंद्रशासित प्रदेश में टक्कर कांग्रेस-NC गठबंधन और BJP में है. PDP और रशीद इंजीनियर की आवामी पार्टी भी मैदान में हैं. इस बीच सबकी नजरें एग्जिट पोल्स पर हैं. एग्जिट पोल्स के अनुमानों में राज्य में किसकी सरकार बन रही है, यहां जानिए पूरा लेखा जोखा.",
      currentYear: "2024",
      assemblyPollData:
        "https://elections.mobiletak.com/jsonfiles/exitpolls/assemblyPollData.json",
    },
    "key-candidates": {
      dataUrl:
        "https://electionresults.intoday.in/elections/2024/assembly-elections/json/ei-dashboard/2024/key-candidate/jammu-and-kashmir-key-candidate.json",
      stateName: "Jammu and Kashmir",
      stateNameHi: "जम्मू और कश्मीर",
      electionYear: "2024",

      tabs: [
        {
          text: "हरियाणा",
          keyToShow: "",
          showActive: false,
          url: "/election/haryana-assembly-chunav/key-candidates",
        },
        {
          text: "जम्मू और कश्मीर",
          keyToShow: "JKkeyCandidate",
          showActive: true,
          url: "/election/jammu-kashmir-assembly-chunav/key-candidates",
        },
      ],
    },
    "party-wise-result": {
      // "state" : "jammu-kashmir",
      state: "Jammu and Kashmir",
      stateHindi: "जम्मू और कश्मीर",
      currentYear: "2024",
      dataUrl:
        "https://elections.mobiletak.com/jsonfiles/exitpolls/assemblyResultData.json",
      dataKey: "partiesDataJK",
      metaKey:
        "Jammu and Kashmir assembly election 2024 results, party-wise results Jammu and Kashmir assembly election result 2024, Jammu and Kashmir vidhan sabha election 2024, Jammu and Kashmir election party-wise seats 2024, Jammu and Kashmir 2024 political party performance, election results 2024 Jammu and Kashmir state, Jammu and Kashmir vidhan sabha party-wise results 2024, जम्मू और कश्मीर विधानसभा चुनाव परिणाम 2024, जम्मू और कश्मीर विधानसभा चुनाव 2024 पार्टी वाइज चुनाव परिणाम",
      otherYearPara: `जम्मू-कश्मीर विधानसभा चुनाव के परिणाम 2024 की घोषणा 8 अक्टूबर को
            मतगणना के बाद की जाएगी। 2014 में हुए पिछले चुनावों में, पीडीपी
            (जम्मू-कश्मीर पीपुल्स डेमोक्रेटिक पार्टी) ने सबसे बड़ी पार्टी के रूप
            में उभरकर 28 सीटें जीती थीं, जिसका नेतृत्व महबूबा मुफ्ती कर रही थीं।
            भारतीय जनता पार्टी (बीजेपी) ने 25 सीटों के साथ दूसरा स्थान प्राप्त
            किया। अन्य दलों में, नेशनल कॉन्फ्रेंस को 15 और कांग्रेस को 12 सीटें
            मिली थीं।`,

      currentYearPara: `Jammu Kashmir Assembly Elections Results 2024: जम्मू-कश्मीर में 10
            साल बाद हुए विधानसभा चुनाव में कौन मार ले जाएगा बाजी? कांग्रेस-NC
            गठबंधन के हाथ आएगी सत्ता या BJP की बनेगी सरकार? PDP और रशीद इंजीनियर
            की आवामी पार्टी का क्या है हाल? सबसे सटीक और तेज नतीजों के लिए बने
            रहिए दी लल्लनटॉप के साथ. नीचे दी गई लिस्ट में देखिए किस पार्टी को
            मिली कितनी सीटें.`,
      tabs: [
        {
          text: "हरियाणा",
          keyToShow: "",
          showActive: false,
          url: "/election/haryana-assembly-chunav/party-wise-result",
        },
        {
          text: "जम्मू और कश्मीर",
          keyToShow: "JKresultpage",
          showActive: true,
          url: "/election/jammu-kashmir-assembly-chunav/party-wise-result",
        },
      ],
    },
    schedule: {
      // "state": "jammu kashmir",
      state: "Jammu and Kashmir",
      stateHindi: "जम्मू और कश्मीर",
      electionYear: "2024",
      metaKey:
        "Jammu and Kashmir Assembly Election Schedule,Jammu and Kashmir Assembly Election Schedule,Jammu and Kashmir Assembly Election 2024,Jammu and Kashmir विधान सभा चुनाव 2024,जम्मू और कश्मीर विधान सभा चुनाव  2024,जम्मू और कश्मीर चुनाव अनुसूची 2024",
      tabs: [
        {
          text: "हरियाणा",
          keyToShow: "",
          showActive: false,
          url: "/election/haryana-assembly-chunav/schedule",
        },
        {
          text: "जम्मू और कश्मीर",
          keyToShow: "",
          showActive: true,
          url: "/election/jammu-kashmir-assembly-chunav/schedule",
        },
      ],
      tables: [
        {
          heading: "जम्मू और कश्मीर",
          phase: "फेज 1",
          on: "18 सितम्बर 2024",
          result: "8 अक्टूबर 2024",
        },
        {
          heading: "जम्मू और कश्मीर",
          phase: "फेज 2",
          on: "25 सितम्बर 2024",
          result: "8 अक्टूबर 2024",
        },
        {
          heading: "जम्मू और कश्मीर",
          phase: "फेज 3",
          on: "1 अक्टूबर 2024",
          result: "8 अक्टूबर 2024",
        },
      ],
    },
    result: {
      state: "Jammu and Kashmir",
      stateHindi: "जम्मू और कश्मीर",
      currentYear: "2024",
      tabs: [
        {
          text: "हरियाणा",
          keyToShow: "",
          showActive: false,
          url: "/election/haryana-assembly-chunav/results",
        },
        {
          text: "जम्मू और कश्मीर",
          keyToShow: "JK_KaunJeeta",
          showActive: true,
          url: "/election/jammu-kashmir-assembly-chunav/results",
        },
      ],
      currentYearPara: `Jammu Kashmir Assembly Elections Results 2024: जम्मू-कश्मीर में 10 साल बाद हुए विधानसभा चुनाव में कौन मार ले जाएगा बाजी? कांग्रेस-NC गठबंधन के हाथ आएगी सत्ता या BJP की बनेगी सरकार? PDP और रशीद इंजीनियर की आवामी पार्टी का क्या है हाल? नीचे दी गई लिस्ट में मिलेगा एक-एक सीट का हाल. अपनी सीट का नाम सर्च कीजिए और जानिए सबसे तेज और सटीक चुनाव नतीजे.`,
      otherYearPara: `Jammu Kashmir Assembly Elections Results 2024: जम्मू-कश्मीर में 10 साल बाद हुए विधानसभा चुनाव में कौन मार ले जाएगा बाजी? कांग्रेस-NC गठबंधन के हाथ आएगी सत्ता या BJP की बनेगी सरकार? PDP और रशीद इंजीनियर की आवामी पार्टी का क्या है हाल? नीचे दी गई लिस्ट में मिलेगा एक-एक सीट का हाल. अपनी सीट का नाम सर्च कीजिए और जानिए सबसे तेज और सटीक चुनाव नतीजे.`,
      prevDataUrl:
        "https://akm-img-a-in.tosshub.com/sites/elections/2024/assembly/json/state-master/jammu-and-kashmir-2019-all-consituency.json",
      currentDataUrl:
        "https://electionresults.intoday.in/elections/2024/assembly-elections/json/state/jammu-and-kashmir-all-consituency.json",
      constituency: {
        // this is used in url
        state: "jammu-kashmir",
        stateHindi: "जम्मू और कश्मीर",
        url: "https://akm-img-a-in.tosshub.com/sites/elections/2024/assembly/json/constituency/jammu-and-kashmir/",
        url3: "https://electionresults.intoday.in/elections/2024/assembly-elections/json/constituency-tak/",
        url2: "https://akm-img-a-in.tosshub.com/sites/elections/2024/assembly/json/state-master/jammu-and-kashmir-state-master.json",
        tabs: [
          {
            text: "हरियाणा",
            keyToShow: "",
            showActive: false,
            url: "/election/haryana-assembly-chunav/results",
          },
          {
            text: "जम्मू और कश्मीर",
            keyToShow: "JKresultpage",
            showActive: true,
            url: "/election/jammu-kashmir-assembly-chunav/results",
          },
        ],
      },
    },
    "masterConfigKey": "vidhansabha_election"
  },

  //maharashtra
  "maharashtra-assembly-chunav": {
    breadCrump: [
      {
        displayText: "कौन जीता-कौन हारा",
        url: "/election/maharashtra-assembly-chunav/results",
        keyToShow: "Maharashtra_KaunJeeta",
      },
      {
        displayText: "प्रमुख उम्मीदवार",
        url: "/election/maharashtra-assembly-chunav/key-candidates",
        keyToShow: "MaharashtraKeyCandidate",
      },
      {
        displayText: "एग्जिट पोल",
        url: "/election/maharashtra-assembly-chunav/exit-poll",
        keyToShow: "MaharashtraExitPollPage",
      },
      {
        displayText: "विधान सभा रिजल्ट",
        url: "/election/maharashtra-assembly-chunav/party-wise-result",
        keyToShow: "MaharashtraResultPage",
      },
      {
        displayText: "शेड्यूल",
        url: "/election/maharashtra-assembly-chunav/schedule",
        keyToShow: "MaharashtraSchedulePage",
      },
      {
        displayText: "सामान्य प्रश्न",
        url: "/election/assembly-chunav/faq",
        keyToShow: "",
      },
    ],
    "masterConfigKey": "Maharashtra_vidhansabha_election",
    "exit-poll": {
      metaKey:
        "Maharashtra Exit Polls 2024, Maharashtra Assembly Election Exit Polls, Exit polls of Maharashtra chunav, Maharashtra exit polls ,Maharashtra exit poll results, Maharashtra election results, महाराष्ट्र, विधानसभा  चुनाव, महाराष्ट्र विधानसभा चुनाव एग्जिट पोल 2024, महाराष्ट्र विधानसभा चुनाव एग्जिट पोल,महाराष्ट्र विधानसभा चुनाव एग्जिट पोल news",
      state: "Maharashtra",
      stateHindi: "महाराष्ट्र",
      agencyName: "AgencyMB",
      tabs: [
        {
          text: "महाराष्ट्र",
          keyToShow: "",
          showActive: true,
          url: "/election/maharashtra-assembly-chunav/exit-poll",
        },
        {
          text: "झारखंड",
          keyToShow: "",
          showActive: false,
          url: "/election/jharkhand-assembly-chunav/exit-poll",
        },
      ],
      textFuture:
        "Maharashtra Assembly Elections Exit Polls 2024: महाराष्ट्र विधानसभा चुनाव के लिए तैयारियां पूरी हो चुकी हैं. जल्द ही मतदान के बाद आ जाएंगे नतीजे. लेकिन नतीजों से पहले सबकी नजर रहेगी एग्जिट पोल्स पर. सबसे सटीक और तेज एग्जिट पोल्स के लिए जुड़े रहिए दी लल्लनटॉप के साथ. तब तक जानिए 2019 के एग्जिट पोल्स. नीचे दी गई लिस्ट में मिलेगी सारी जानकारी.",
      textPast:
        "Maharashtra Assembly Elections Exit Polls 2024: महाराष्ट्र विधानसभा चुनाव के लिए तैयारियां पूरी हो चुकी हैं. जल्द ही मतदान के बाद आ जाएंगे नतीजे. लेकिन नतीजों से पहले सबकी नजर रहेगी एग्जिट पोल्स पर. सबसे सटीक और तेज एग्जिट पोल्स के लिए जुड़े रहिए दी लल्लनटॉप के साथ.",
      currentYear: "2024",
      2019: {
        type: "static",
        data: [
          {
            agencyName: "Axis My India",
            partyData: [
              {
                partyName: "BJP",
                imageUrl:
                  "https://akm-img-a-in.tosshub.com/sites/electiontak/images/counting-day/partylogo-118x118/bjp.jpg",
                seats: "109-124",
                midRange: 24,
                rgb: "rgb(232, 71, 0)",
                percentage: "40.27%",
              },
              {
                partyName: "SENA",
                imageUrl:
                  "https://static.thelallantop.com/images/post/1730886686983_logo_of_shiv_sena.svg.webp",
                seats: "57-70",
                midRange: 54,
                rgb: "rgb(232, 71, 0)",
                percentage: "21.87%",
              },
              {
                partyName: "CONG",
                seats: "32-40",
                imageUrl:
                  "https://akm-img-a-in.tosshub.com/sites/electiontak/images/counting-day/partylogo-118x118/inc.jpg",
                midRange: 13,
                rgb: "rgb(232, 71, 0)",
                percentage: "12.5%",
              },
              {
                partyName: "NCP",
                seats: "40-50",
                imageUrl:
                  "https://static.thelallantop.com/images/post/1730886686983_logo_of_shiv_sena.svg.webp",
                midRange: 24,
                rgb: "rgb(232, 71, 0)",
                percentage: "15.6%",
              },
              {
                partyName: "VBA",
                seats: "0-2",
                imageUrl:
                  "https://static.thelallantop.com/images/post/1730886709609_vba_party.webp",
                midRange: 54,
                rgb: "rgb(232, 71, 0)",
                percentage: "0.34%",
              },
              {
                partyName: "OTH",
                seats: "22-32",
                imageUrl:
                  "https://akm-img-a-in.tosshub.com/sites/electiontak/images/counting-day/partylogo-118x118/others.jpg",
                midRange: 13,
                rgb: "rgb(232, 71, 0)",
                percentage: "9.37%",
              },
            ],
            halfWayMark: 144,
          },
        ],
      },
      2024: {
        type: "api",
        data: "https://elections.mobiletak.com/jsonfiles/exitpolls/assemblyPollData.json",
        dataKey: "AgencyMB",
      },
      // "assemblyPollData": "https://elections.mobiletak.com/jsonfiles/exitpolls/assemblyPollData.json"
    },
    "key-candidates": {
      dataUrl:
        "https://electionresults.intoday.in/elections/2024/assembly-elections/json/ei-dashboard/2024/key-candidate/maharashtra-key-candidate.json",
      dataKey: "Maharashtra",
      dataUrlKey: "MH_KeyCandidatesURL",

      // this name is also used inside the code for the logic so name as per the api
      //this needs to be same as the STATE variable that comes in the data from the api key-candidates
      stateName: "Maharashtra",
      stateNameHi: "महाराष्ट्र",
      electionYear: "2024",
      textPara: `Maharashtra Assembly Election 2024 Key-Candidate List: महाराष्ट्र
                  विधानसभा चुनाव में एक तरफ अलग-अलग पार्टियों के बीच टक्कर है,
                  वहीं दूसरी तरफ कई बड़े चेहरों की प्रतिष्ठा भी दांव पर है. कौन
                  से बड़े चेहरे कहां से लड़ रहे हैं चुनाव? जमीन पर कैसे बन-बिगड़
                  रहे हैं समीकरण? क्या होने जा रहा है कोई बड़ा उलटफेर? नीचे दी
                  गई लिस्ट में जानिए पूरा हाल.`,
      tabs: [
        {
          text: "महाराष्ट्र",
          keyToShow: "MaharashtraKeyCandidate",
          showActive: true,
          url: "/election/maharashtra-assembly-chunav/key-candidates",
        },
        {
          text: "झारखंड",
          keyToShow: "",
          showActive: false,
          url: "/election/jharkhand-assembly-chunav/key-candidates",
        },
      ],
    },
    "party-wise-result": {
      state: "Maharashtra",
      stateHindi: "महाराष्ट्र",
      currentYear: "2024",
      // "dataUrl": "https://elections.mobiletak.com/jsonfiles/exitpolls/assemblyResultData.json",
      2019: {
        type: "static",
        data: [
          {
            partyName: "BJP",
            seatShare: 105,
          },
          {
            partyName: "SHS",
            seatShare: 56,
          },
          {
            partyName: "NCP",
            seatShare: 54,
          },
          {
            partyName: "INC",
            seatShare: 44,
          },
          {
            partyName: "OTH",
            seatShare: 29,
          },
        ],
      },
      2024: {
        type: "api",
        data: "https://elections.mobiletak.com/jsonfiles/exitpolls/assemblyResultData.json",
        dataKey: "partiesDataMH",
      },
      // "dataKey": "partiesDataHR",
      metaKey:
        "Maharashtra assembly election 2024 results, party-wise results maharashtra assembly election result 2024, maharashtra vidhan sabha election 2024, maharashtra election party-wise seats 2024, maharashtra 2024 political party performance, election results 2024 maharashtra state, maharashtra vidhan sabha party-wise results 2024, महाराष्ट्र विधानसभा चुनाव परिणाम 2024, महाराष्ट्र विधानसभा चुनाव 2024 पार्टी वाइज चुनाव परिणाम",
      currentYearPara: `Maharashtra Assembly Elections Results 2024: इस बार के महाराष्ट्र विधानसभा चुनाव में टक्कर दो गठबंधनों के बीच है. एक तरफ है BJP, शिवसेना (शिंदे गुट) और NCP (अजित गुट) का महायुति गठबंधन और दूसरी तरफ है कांग्रेस, शिवसेना (उद्धव गुट) और NCP (शरद गुट) का महाअघाड़ी गठबंधन. इस बार कौन सा गठबंधन मारेगा बाजी? कौन सी पार्टी को मिलेंगी कितनी सीटें? सबसे सटीक और तेज नतीजों के लिए जुड़े रहिए दी लल्लनटॉप से.`,
      otherYearPara: `Maharashtra Assembly Elections Results 2024: इस बार के महाराष्ट्र विधानसभा चुनाव में टक्कर दो गठबंधनों के बीच है. एक तरफ है BJP, शिवसेना (शिंदे गुट) और NCP (अजित गुट) का महायुति गठबंधन और दूसरी तरफ है कांग्रेस, शिवसेना (उद्धव गुट) और NCP (शरद गुट) का महाअघाड़ी गठबंधन. इस बार कौन सा गठबंधन मारेगा बाजी? कौन सी पार्टी को मिलेंगी कितनी सीटें? सबसे सटीक और तेज नतीजों के लिए जुड़े रहिए दी लल्लनटॉप से. तब तक जानिए 2019 के चुनाव में किस पार्टी का क्या हाल रहा था. नीचे दी गई लिस्ट में मिलेगी सारी जानकारी.`,
      tabs: [
        {
          text: "महाराष्ट्र",
          keyToShow: "",
          showActive: true,
          url: "/election/maharashtra-assembly-chunav/party-wise-result",
        },
        {
          text: "झारखंड",
          keyToShow: "JKresultpage",
          showActive: false,
          url: "/election/jharkhand-assembly-chunav/party-wise-result",
        },
      ],
    },
    schedule: {
      state: "Maharashtra",
      stateHindi: "महाराष्ट्र",
      electionYear: "2024",
      metaKey:
        "Haryana Assembly Election Schedule,Haryana Assembly Election Schedule,Haryana Assembly Election 2024,Haryana विधान सभा चुनाव 2024,हरियाणा विधान सभा चुनाव  2024,हरियाणा चुनाव अनुसूची 2024",

      tabs: [
        {
          text: "महाराष्ट्र",
          keyToShow: "",
          showActive: true,
          url: "/election/maharashtra-assembly-chunav/schedule",
        },
        {
          text: "झारखंड",
          keyToShow: "",
          showActive: false,
          url: "/election/jharkhand-assembly-chunav/schedule",
        },
      ],
      tables: [
        {
          heading: "महाराष्ट्र",
          phase: "फेज 1",
          on: "20 नवंबर 2024",
          result: "23 नवंबर 2024",
        },
      ],
    },
    result: {
      state: "Maharashtra",
      stateHindi: "महाराष्ट्र",
      currentYear: "2024",
      tabs: [
        {
          text: "महाराष्ट्र",
          keyToShow: "",
          showActive: true,
          url: "/election/maharashtra-assembly-chunav/results",
        },
        {
          text: "झारखंड",
          keyToShow: "",
          showActive: false,
          url: "/election/jharkhand-assembly-chunav/results",
        },
      ],
      currentYearPara: `Maharashtra Assembly Elections Results 2024: इस बार के महाराष्ट्र विधानसभा चुनाव में कौन मारेगा बाजी? BJP, शिवसेना (शिंदे गुट) और NCP (अजित गुट) का महायुति गठबंधन को मिलेगा बहुमत या कांग्रेस, शिवसेना (उद्धव गुट) और NCP (शरद गुट) वाला महाअघाड़ी गठबंधन दोहराएगा 2024 लोकसभा चुनाव का प्रदर्शन? सबसे सटीक और तेज नतीजों के लिए बने रहिए दी लल्लनटॉप के साथ.`,
      otherYearPara: `Maharashtra Assembly Elections Results 2024: इस बार के महाराष्ट्र विधानसभा चुनाव में कौन मारेगा बाजी? BJP, शिवसेना (शिंदे गुट) और NCP (अजित गुट) का महायुति गठबंधन को मिलेगा बहुमत या कांग्रेस, शिवसेना (उद्धव गुट) और NCP (शरद गुट) वाला महाअघाड़ी गठबंधन दोहराएगा 2024 लोकसभा चुनाव का प्रदर्शन? सबसे सटीक और तेज नतीजों के लिए बने रहिए दी लल्लनटॉप के साथ. नीचे दी गई लिस्ट में जानिए 2019 के महाराष्ट्र विधानसभा चुनाव का रिजल्ट क्या रहा था.`,
      prevDataUrl:
        "https://akm-img-a-in.tosshub.com/sites/elections/2024/assembly/json/state-master/maharashtra-2014-all-consituency.json",
      currentDataUrl:
        "https://akm-img-a-in.tosshub.com/sites/elections/2024/assembly/json/state-master/maharashtra-2019-all-consituency.json",
      2014: "https://akm-img-a-in.tosshub.com/sites/elections/2024/assembly/json/state-master/maharashtra-2014-all-consituency.json",
      2019: "https://akm-img-a-in.tosshub.com/sites/elections/2024/assembly/json/state-master/maharashtra-2019-all-consituency.json",
      // "2024" : "https://akm-img-a-in.tosshub.com/sites/elections/2024/assembly/json/state-master/maharashtra-2019-all-consituency.json",
      2024: "https://electionresults.intoday.in/elections/2024/assembly-elections/json/state/maharashtra-all-consituency.json",
      constituency: {
        //this is used in url
        state: "Maharashtra",
        stateHindi: "महाराष्ट्र",
        url3Key: "ConstituencyDetailURL",
        prevYear: [2019, 2014],
        url: "https://akm-img-a-in.tosshub.com/sites/elections/2024/assembly/json/constituency/maharashtra/",
        // "url3": "https://electionresults.intoday.in/elections/2024/assembly-elections/json/constituency-tak/7047",
        url3: "https://electionresults.intoday.in/elections/2024/assembly-elections/json/constituency-tak/",
        url2: "https://akm-img-a-in.tosshub.com/sites/elections/2024/assembly/json/state-master/maharashtra-state-master.json",
        tabs: [
          {
            text: "महाराष्ट्र",
            keyToShow: "",
            showActive: true,
            url: "/election/maharashtra-assembly-chunav/results",
          },
          {
            text: "झारखंड",
            keyToShow: "",
            showActive: false,
            url: "/election/jharkhand-assembly-chunav/results",
          },
        ],
      },
    },
    tallyState: "maharashtra",
    candidates : {
      candidate : {
        dataUrl : 'https://electionresults.intoday.in/elections/2025/assembly-elections/json/constituency-tak'
      }
    }
  },

  //jhanrkhand
  "jharkhand-assembly-chunav": {
    breadCrump: [
      {
        displayText: "कौन जीता-कौन हारा",
        url: "/election/jharkhand-assembly-chunav/results",
        keyToShow: "Jharkhand_KaunJeeta",
      },
      {
        displayText: "प्रमुख उम्मीदवार",
        url: "/election/jharkhand-assembly-chunav/key-candidates",
        keyToShow: "JharkhandKeyCandidate",
      },
      {
        displayText: "एग्जिट पोल",
        url: "/election/jharkhand-assembly-chunav/exit-poll",
        keyToShow: "JharkhandExitPollPage",
      },
      {
        displayText: "विधान सभा रिजल्ट",
        url: "/election/jharkhand-assembly-chunav/party-wise-result",
        keyToShow: "JharkhandResultPage",
      },
      {
        displayText: "शेड्यूल",
        url: "/election/jharkhand-assembly-chunav/schedule",
        keyToShow: "JharkhandSchedulePage",
      },
      {
        displayText: "सामान्य प्रश्न",
        url: "/election/assembly-chunav/faq",
        keyToShow: "",
      },
    ],
    "masterConfigKey": "Maharashtra_vidhansabha_election",
    "exit-poll": {
      metaKey:
        "Jharkhand Exit Polls 2024, Jharkhand Assembly Election Exit Polls, Exit polls of Jharkhand chunav, Jharkhand exit polls ,Jharkhand exit poll results, Jharkhand election results, झारखंड, विधानसभा  चुनाव, झारखंड विधानसभा चुनाव एग्जिट पोल 2024, झारखंड विधानसभा चुनाव एग्जिट पोल,झारखंड विधानसभा चुनाव एग्जिट पोल news",
      state: "Jharkhand",
      stateHindi: "झारखंड",
      agencyName: "AgencyHaryana",
      tabs: [
        {
          text: "महाराष्ट्र",
          keyToShow: "",
          showActive: false,
          url: "/election/maharashtra-assembly-chunav/exit-poll",
        },
        {
          text: "झारखंड",
          keyToShow: "",
          showActive: true,
          url: "/election/jharkhand-assembly-chunav/exit-poll",
        },
      ],
      textFuture:
        "Jharkhand Assembly Elections Exit Polls 2024: झारखंड विधानसभा चुनाव की सरगर्मी बढ़ी हुई है. जल्द ही राज्य में होगा मतदान और फिर आएंगे नतीजे. लेकिन नतीजों से पहले सबकी नजर रहेगी एग्जिट पोल्स पर. सबसे सटीक और तेज एग्जिट पोल्स के लिए जुड़े रहिए दी लल्लनटॉप के साथ. तब तक जानिए 2019 के एग्जिट पोल्स. नीचे दी गई लिस्ट में मिलेगी सारी जानकारी.",
      textPast:
        "Jharkhand Assembly Elections Exit Polls 2024: झारखंड विधानसभा चुनाव की सरगर्मी बढ़ी हुई है. जल्द ही राज्य में होगा मतदान और फिर आएंगे नतीजे. लेकिन नतीजों से पहले सबकी नजर रहेगी एग्जिट पोल्स पर. सबसे सटीक और तेज एग्जिट पोल्स के लिए जुड़े रहिए दी लल्लनटॉप के साथ.",
      currentYear: "2024",
      2019: {
        type: "static",
        data: [
          {
            agencyName: "Axis My India",
            partyData: [
              {
                partyName: "BJP",
                imageUrl:
                  "https://akm-img-a-in.tosshub.com/sites/electiontak/images/counting-day/partylogo-118x118/bjp.jpg",
                seats: "22-32",
                midRange: 24,
                rgb: "rgb(232, 71, 0)",
                percentage: "33.33%",
              },
              {
                partyName: "JMM+CONG",
                imageUrl:
                  "https://static.thelallantop.com/images/post/1730886686983_logo_of_shiv_sena.svg.webp",
                seats: "38-50",
                midRange: 54,
                rgb: "rgb(232, 71, 0)",
                percentage: "54.3%",
              },
              {
                partyName: "JVM",
                seats: "2-4",
                imageUrl:
                  "https://akm-img-a-in.tosshub.com/sites/electiontak/images/counting-day/partylogo-118x118/inc.jpg",
                midRange: 13,
                rgb: "rgb(232, 71, 0)",
                percentage: "3.7%",
              },
              {
                partyName: "AJSU",
                seats: "3-5",
                imageUrl:
                  "https://static.thelallantop.com/images/post/1730886686983_logo_of_shiv_sena.svg.webp",
                midRange: 24,
                rgb: "rgb(232, 71, 0)",
                percentage: "4.9%",
              },
              {
                partyName: "OTH",
                seats: "4-7",
                imageUrl:
                  "https://akm-img-a-in.tosshub.com/sites/electiontak/images/counting-day/partylogo-118x118/others.jpg",
                midRange: 13,
                rgb: "rgb(232, 71, 0)",
                percentage: "7.4%",
              },
            ],
            halfWayMark: 41,
          },
        ],
      },
      2024: {
        type: "api",
        data: "https://elections.mobiletak.com/jsonfiles/exitpolls/assemblyPollData.json",
        dataKey: "AgencyJH",
      },
      // "assemblyPollData": "https://elections.mobiletak.com/jsonfiles/exitpolls/assemblyPollData.json"
    },
    "key-candidates": {
      dataUrl:
        "https://electionresults.intoday.in/elections/2024/assembly-elections/json/ei-dashboard/2024/key-candidate/jharkhand-key-candidate.json",
      dataKey: "Jharkhand",
      dataUrlKey: "JH_KeyCandidatesURL",

      // this name is also used inside the code for the logic so name as per the api
      //this needs to be same as the STATE variable that comes in the data from the api key-candidates
      stateName: "Jharkhand",
      stateNameHi: "झारखंड",
      electionYear: "2024",
      textPara: `Jharkhand Assembly Election 2024 Key-Candidate List: झारखंड
                  विधानसभा चुनाव में एक तरफ अलग-अलग पार्टियों के बीच टक्कर है,
                  वहीं दूसरी तरफ कई बड़े चेहरों की प्रतिष्ठा भी दांव पर है. कौन
                  से बड़े चेहरे कहां से लड़ रहे हैं चुनाव? जमीन पर कैसे बन-बिगड़
                  रहे हैं समीकरण? क्या होने जा रहा है कोई बड़ा उलटफेर? नीचे दी
                  गई लिस्ट में जानिए पूरा हाल.`,
      tabs: [
        {
          text: "महाराष्ट्र",
          keyToShow: "MaharashtraKeyCandidate",
          showActive: false,
          url: "/election/maharashtra-assembly-chunav/key-candidates",
        },
        {
          text: "झारखंड",
          keyToShow: "",
          showActive: true,
          url: "/election/jharkhand-assembly-chunav/key-candidates",
        },
      ],
    },
    "party-wise-result": {
      state: "Jharkhand",
      stateHindi: "झारखंड",
      currentYear: "2024",
      dataUrl:
        "https://elections.mobiletak.com/jsonfiles/exitpolls/assemblyResultData.json",
      2019: {
        type: "static",
        data: [
          {
            partyName: "BJP",
            seatShare: 25,
          },
          {
            partyName: "JMM",
            seatShare: 30,
          },
          {
            partyName: "INC",
            seatShare: 16,
          },
          {
            partyName: "JVM",
            seatShare: 3,
          },
          {
            partyName: "RJD",
            seatShare: 1,
          },
          {
            partyName: "OTHER",
            seatShare: 6,
          },
        ],
      },
      2024: {
        type: "api",
        data: "https://elections.mobiletak.com/jsonfiles/exitpolls/assemblyResultData.json",
        dataKey: "partiesDataJH",
      },
      // "dataKey": "partiesDataHR",
      metaKey:
        "Jharkhand assembly election 2024 results, party-wise results jharkhand assembly election result 2024, jharkhand vidhan sabha election 2024, jharkhand election party-wise seats 2024, jharkhand 2024 political party performance, election results 2024 jharkhand state, jharkhand vidhan sabha party-wise results 2024, झारखंड विधानसभा चुनाव परिणाम 2024, झारखंड विधानसभा चुनाव 2024 पार्टी वाइज चुनाव परिणाम",
      currentYearPara: `Jharkhand Assembly Elections Results 2024: झारखंड विधानसभा चुनाव 2024 में टक्कर JMM-कांग्रेस वाले INDIA और BJP-AJSU वाले NDA गठबंधनों के बीच है. इस बार चलेगा किसका सिक्का? किस पार्टी को मिलेंगी कितनी सीटें? सबसे सटीक और तेज नतीजों के लिए जुड़े रहिए दी लल्लनटॉप से.`,
      otherYearPara: `Jharkhand Assembly Elections Results 2024: झारखंड विधानसभा चुनाव 2024 में टक्कर JMM-कांग्रेस वाले INDIA और BJP-AJSU वाले NDA गठबंधनों के बीच है. इस बार चलेगा किसका सिक्का? किस पार्टी को मिलेंगी कितनी सीटें? सबसे सटीक और तेज नतीजों के लिए जुड़े रहिए दी लल्लनटॉप से. तब तक जानिए 2019 के चुनाव में किस पार्टी का क्या हाल रहा था. नीचे दी गई लिस्ट में मिलेगी सारी जानकारी`,
      tabs: [
        {
          text: "महाराष्ट्र",
          keyToShow: "",
          showActive: false,
          url: "/election/maharashtra-assembly-chunav/party-wise-result",
        },
        {
          text: "झारखंड",
          keyToShow: "JKresultpage",
          showActive: true,
          url: "/election/jharkhand-assembly-chunav/party-wise-result",
        },
      ],
    },
    schedule: {
      state: "Jharkhand",
      stateHindi: "झारखंड",
      tables: [
        {
          heading: "झारखंड",
          phase: "फेज 1",
          on: "13 नवंबर 2024",
          result: "23 नवंबर 2024",
        },
        {
          heading: "झारखंड",
          phase: "फेज 2",
          on: "20 नवंबर 2024",
          result: "23 नवंबर 2024",
        },
      ],
      tabs: [
        {
          text: "महाराष्ट्र",
          keyToShow: "",
          showActive: false,
          url: "/election/maharashtra-assembly-chunav/schedule",
        },
        {
          text: "झारखंड",
          keyToShow: "",
          showActive: true,
          url: "/election/jharkhand-assembly-chunav/schedule",
        },
      ],
      electionYear: "2024",
      metaKey:
        "Haryana Assembly Election Schedule,Haryana Assembly Election Schedule,Haryana Assembly Election 2024,Haryana विधान सभा चुनाव 2024,हरियाणा विधान सभा चुनाव  2024,हरियाणा चुनाव अनुसूची 2024",
    },
    result: {
      state: "Jharkhand",
      stateHindi: "झारखंड",
      currentYear: "2024",
      tabs: [
        {
          text: "महाराष्ट्र",
          keyToShow: "",
          showActive: false,
          url: "/election/maharashtra-assembly-chunav/results",
        },
        {
          text: "झारखंड",
          keyToShow: "",
          showActive: true,
          url: "/election/jharkhand-assembly-chunav/results",
        },
      ],
      currentYearPara: `Jharkhand Assembly Elections Results 2024: इस बार के झारखंड विधानसभा चुनाव में चल रही है किसकी हवा? JMM-कांग्रेस का INDIA गठबंधन बचा लेगा अपनी सरकार या BJP-AJSU वाला NDA गठबंधन मार ले जाएगा बाजी? सबसे सटीक और तेज नतीजों के लिए बने रहिए दी लल्लनटॉप के साथ.`,
      otherYearPara: `Jharkhand Assembly Elections Results 2024: इस बार के झारखंड विधानसभा चुनाव में चल रही है किसकी हवा? JMM-कांग्रेस का INDIA गठबंधन बचा लेगा अपनी सरकार या BJP-AJSU वाला NDA गठबंधन मार ले जाएगा बाजी? सबसे सटीक और तेज नतीजों के लिए बने रहिए दी लल्लनटॉप के साथ. नीचे दी गई लिस्ट में जानिए क्या रहा था झारखंड विधानसभा चुनाव 2019 का रिजल्ट.
 `,
      prevDataUrl:
        "https://akm-img-a-in.tosshub.com/sites/elections/2024/assembly/json/state-master/maharashtra-2014-all-consituency.json",
      currentDataUrl:
        "https://akm-img-a-in.tosshub.com/sites/elections/2024/assembly/json/state-master/maharashtra-2019-all-consituency.json",
      2014: "https://akm-img-a-in.tosshub.com/sites/elections/2024/assembly/json/state-master/jharkhand-2014-all-consituency.json",
      2019: "https://akm-img-a-in.tosshub.com/sites/elections/2024/assembly/json/state-master/jharkhand-2019-all-consituency.json",
      2024: "https://electionresults.intoday.in/elections/2024/assembly-elections/json/state/jharkhand-all-consituency.json",
      // "2024" : "https://electionresults.intoday.in/elections/2024/assembly-elections/json/state/haryana-all-consituency.json",
      constituency: {
        //this is used in url
        state: "Jharkhand",
        stateHindi: "झारखंड",
        url3Key: "ConstituencyDetailURL",
        prevYear: [2019, 2014],
        url: "https://akm-img-a-in.tosshub.com/sites/elections/2024/assembly/json/constituency/jharkhand/",
        // "url3": "https://electionresults.intoday.in/elections/2024/assembly-elections/json/constituency-alpha/",
        url3: "https://electionresults.intoday.in/elections/2024/assembly-elections/json/constituency-tak/",
        url2: "https://akm-img-a-in.tosshub.com/sites/elections/2024/assembly/json/state-master/jharkhand-state-master.json",
        tabs: [
          {
            text: "महाराष्ट्र",
            keyToShow: "",
            showActive: false,
            url: "/election/maharashtra-assembly-chunav/results",
          },
          {
            text: "झारखंड",
            keyToShow: "",
            showActive: true,
            url: "/election/jharkhand-assembly-chunav/results",
          },
        ],
      },
    },
    tallyState: "jharkhand",
    candidates : {
      candidate : {
        dataUrl : 'https://electionresults.intoday.in/elections/2025/assembly-elections/json/constituency-tak'
      }
    }
  },


  //delhi
  //jhanrkhand
  "delhi-assembly-chunav": {
    breadCrump: [
      {
        displayText: "कौन जीता-कौन हारा",
        url: "/election/delhi-assembly-chunav/results",
        keyToShow: "Delhi_KaunJeeta",
      },
      {
        displayText: "प्रमुख उम्मीदवार",
        url: "/election/delhi-assembly-chunav/key-candidates",
        keyToShow: "DelhiKeyCandidate",
      },
      {
        displayText: "एग्जिट पोल",
        url: "/election/delhi-assembly-chunav/exit-poll",
        keyToShow: "DelhiExitPollPage",
      },
      {
        displayText: "विधान सभा रिजल्ट",
        url: "/election/delhi-assembly-chunav/party-wise-result",
        keyToShow: "DelhiResultPage",
      },
      {
        displayText: "शेड्यूल",
        url: "/election/delhi-assembly-chunav/schedule",
        keyToShow: "DelhiSchedulePage",
      },
      {
        displayText: "सामान्य प्रश्न",
        url: "/election/assembly-chunav/faq",
        keyToShow: "",
      },
    ],
    "masterConfigKey": "Delhi_vidhansabha_election",
    // "exit-poll": {
    //   metaKey:
    //     "Jharkhand Exit Polls 2024, Jharkhand Assembly Election Exit Polls, Exit polls of Jharkhand chunav, Jharkhand exit polls ,Jharkhand exit poll results, Jharkhand election results, झारखंड, विधानसभा  चुनाव, झारखंड विधानसभा चुनाव एग्जिट पोल 2024, झारखंड विधानसभा चुनाव एग्जिट पोल,झारखंड विधानसभा चुनाव एग्जिट पोल news",
    //   state: "Jharkhand",
    //   stateHindi: "झारखंड",
    //   agencyName: "AgencyHaryana",
    //   tabs: [
    //     {
    //       text: "महाराष्ट्र",
    //       keyToShow: "",
    //       showActive: false,
    //       url: "/election/maharashtra-assembly-chunav/exit-poll",
    //     },
    //     {
    //       text: "झारखंड",
    //       keyToShow: "",
    //       showActive: true,
    //       url: "/election/jharkhand-assembly-chunav/exit-poll",
    //     },
    //   ],
    //   textFuture:
    //     "Jharkhand Assembly Elections Exit Polls 2024: झारखंड विधानसभा चुनाव की सरगर्मी बढ़ी हुई है. जल्द ही राज्य में होगा मतदान और फिर आएंगे नतीजे. लेकिन नतीजों से पहले सबकी नजर रहेगी एग्जिट पोल्स पर. सबसे सटीक और तेज एग्जिट पोल्स के लिए जुड़े रहिए दी लल्लनटॉप के साथ. तब तक जानिए 2019 के एग्जिट पोल्स. नीचे दी गई लिस्ट में मिलेगी सारी जानकारी.",
    //   textPast:
    //     "Jharkhand Assembly Elections Exit Polls 2024: झारखंड विधानसभा चुनाव की सरगर्मी बढ़ी हुई है. जल्द ही राज्य में होगा मतदान और फिर आएंगे नतीजे. लेकिन नतीजों से पहले सबकी नजर रहेगी एग्जिट पोल्स पर. सबसे सटीक और तेज एग्जिट पोल्स के लिए जुड़े रहिए दी लल्लनटॉप के साथ.",
    //   currentYear: "2024",
    //   2019: {
    //     type: "static",
    //     data: [
    //       {
    //         agencyName: "Axis My India",
    //         partyData: [
    //           {
    //             partyName: "BJP",
    //             imageUrl:
    //               "https://akm-img-a-in.tosshub.com/sites/electiontak/images/counting-day/partylogo-118x118/bjp.jpg",
    //             seats: "22-32",
    //             midRange: 24,
    //             rgb: "rgb(232, 71, 0)",
    //             percentage: "33.33%",
    //           },
    //           {
    //             partyName: "JMM+CONG",
    //             imageUrl:
    //               "https://static.thelallantop.com/images/post/1730886686983_logo_of_shiv_sena.svg.webp",
    //             seats: "38-50",
    //             midRange: 54,
    //             rgb: "rgb(232, 71, 0)",
    //             percentage: "54.3%",
    //           },
    //           {
    //             partyName: "JVM",
    //             seats: "2-4",
    //             imageUrl:
    //               "https://akm-img-a-in.tosshub.com/sites/electiontak/images/counting-day/partylogo-118x118/inc.jpg",
    //             midRange: 13,
    //             rgb: "rgb(232, 71, 0)",
    //             percentage: "3.7%",
    //           },
    //           {
    //             partyName: "AJSU",
    //             seats: "3-5",
    //             imageUrl:
    //               "https://static.thelallantop.com/images/post/1730886686983_logo_of_shiv_sena.svg.webp",
    //             midRange: 24,
    //             rgb: "rgb(232, 71, 0)",
    //             percentage: "4.9%",
    //           },
    //           {
    //             partyName: "OTH",
    //             seats: "4-7",
    //             imageUrl:
    //               "https://akm-img-a-in.tosshub.com/sites/electiontak/images/counting-day/partylogo-118x118/others.jpg",
    //             midRange: 13,
    //             rgb: "rgb(232, 71, 0)",
    //             percentage: "7.4%",
    //           },
    //         ],
    //         halfWayMark: 41,
    //       },
    //     ],
    //   },
    //   2024: {
    //     type: "api",
    //     data: "https://elections.mobiletak.com/jsonfiles/exitpolls/assemblyPollData.json",
    //     dataKey: "AgencyJH",
    //   },
    //   // "assemblyPollData": "https://elections.mobiletak.com/jsonfiles/exitpolls/assemblyPollData.json"
    // },
    "key-candidates": {
      dataUrl:
        "https://electionresults.intoday.in/elections/2024/assembly-elections/json/ei-dashboard/2024/key-candidate/jharkhand-key-candidate.json",
      dataKey: "Delhi",
      dataUrlKey: "Delhi_KeyCandidatesURL",

      // this name is also used inside the code for the logic so name as per the api
      //this needs to be same as the STATE variable that comes in the data from the api key-candidates
      stateName: "Delhi",
      stateNameHi: "दिल्ली",
      electionYear: "2025",
      textPara: `Delhi Assembly Election 2025 Key-Candidate List: दिल्ली
                    विधानसभा चुनाव में एक तरफ अलग-अलग पार्टियों के बीच टक्कर है,
                    वहीं दूसरी तरफ कई बड़े चेहरों की प्रतिष्ठा भी दांव पर है. कौन
                    से बड़े चेहरे कहां से लड़ रहे हैं चुनाव? जमीन पर कैसे बन-बिगड़
                    रहे हैं समीकरण? क्या होने जा रहा है कोई बड़ा उलटफेर? नीचे दी
                    गई लिस्ट में जानिए पूरा हाल.`,
      tabs: [
      ],
    },
    "party-wise-result": {
      state: "Delhi",
      stateHindi: "दिल्ली",
      currentYear: "2025",
      dataUrl:
        "https://elections.mobiletak.com/jsonfiles/exitpolls/assemblyResultData.json",
      2020: {
        type: "static",
        data: [
          {
            partyName: "AAP",
            seatShare: 62,
          },
          {
            partyName: "BJP",
            seatShare: 8,
          },

        ],
      },
      2025: {
        type: "api",
        data: "https://elections.mobiletak.com/jsonfiles/exitpolls/assemblyResultData.json",
        dataKey: "partiesDataDL",
      },
      // "dataKey": "partiesDataHR",
      metaKey:
        "Delhi assembly election 2025 results, party-wise results delhi assembly election result 2025, delhi vidhan sabha election 2025, delhi election party-wise seats 2025, delhi 2025 political party performance, election results 2025 delhi state, delhi vidhan sabha party-wise results 2025, दिल्ली विधानसभा चुनाव परिणाम 2025, दिल्ली विधानसभा चुनाव 2025 पार्टी वाइज चुनाव परिणाम",
      currentYearPara: `Delhi Assembly Elections Results 2025: इस बार के दिल्ली विधानसभा चुनाव में कौन मारेगा बाजी? आम आदमी पार्टी (AAP) लगातार चौथी बार बनाएगी सरकार या भारतीय जनता पार्टी (BJP) को मिलेगा जनता का साथ या कांग्रेस (Congress) कर सकती है कमाल? सबसे सटीक और तेज नतीजों के लिए बने रहिए दी लल्लनटॉप के साथ. 
 `,
      otherYearPara: `Delhi Assembly Elections Results 2025: इस बार के दिल्ली विधानसभा चुनाव में कौन मारेगा बाजी? आम आदमी पार्टी (AAP) लगातार चौथी बार बनाएगी सरकार या भारतीय जनता पार्टी (BJP) को मिलेगा जनता का साथ या कांग्रेस (Congress) कर सकती है कमाल? सबसे सटीक और तेज नतीजों के लिए बने रहिए दी लल्लनटॉप के साथ. 
  तब तक जानिए 2020 के चुनाव में किस पार्टी का क्या हाल रहा था.`,
      tabs: [
        // {
        //   text: "महाराष्ट्र",
        //   keyToShow: "",
        //   showActive: false,
        //   url: "/election/maharashtra-assembly-chunav/party-wise-result",
        // },
        // {
        //   text: "झारखंड",
        //   keyToShow: "JKresultpage",
        //   showActive: true,
        //   url: "/election/jharkhand-assembly-chunav/party-wise-result",
        // },
      ],
    },
    schedule: {
      state: "Delhi",
      stateHindi: "दिल्ली",
      tables: [
        {
          heading: "दिल्ली",
          phase: "फेज 1",
          on: "5 फ़रवरी 2025",
          result: "8 फ़रवरी 2025",
        },
      ],
      tabs: [],
      electionYear: "2025",
      metaKey:
        "Delhi Assembly Election Schedule,Delhi Assembly Election Schedule,Delhi Assembly Election 2025,Delhi विधान सभा चुनाव 2025,दिल्ली विधान सभा चुनाव  2025,दिल्ली चुनाव अनुसूची 2025",
    },
    result: {
      state: "Delhi",
      stateHindi: "दिल्ली",
      currentYear: "2025",
      tabs: [],
      currentYearPara: `Delhi Assembly Elections Results 2025: इस बार के दिल्ली विधानसभा चुनाव में कौन मारेगा बाजी? आम आदमी पार्टी (AAP) लगातार चौथी बार बनाएगी सरकार या भारतीय जनता पार्टी (BJP) को मिलेगा जनता का साथ या कांग्रेस (Congress) कर सकती है कमाल? सबसे सटीक और तेज नतीजों के लिए बने रहिए दी लल्लनटॉप के साथ. 
 `,
      otherYearPara: `Delhi Assembly Elections Results 2025: इस बार के दिल्ली विधानसभा चुनाव में कौन मारेगा बाजी? आम आदमी पार्टी (AAP) लगातार चौथी बार बनाएगी सरकार या भारतीय जनता पार्टी (BJP) को मिलेगा जनता का साथ या कांग्रेस (Congress) कर सकती है कमाल? सबसे सटीक और तेज नतीजों के लिए बने रहिए दी लल्लनटॉप के साथ. 
  नीचे दी गई लिस्ट में जानिए क्या रहा था दिल्ली विधानसभा चुनाव 2020 का रिजल्ट.
   `,
      prevDataUrl:
        "https://akm-img-a-in.tosshub.com/sites/elections/2024/assembly/json/state-master/maharashtra-2014-all-consituency.json",
      currentDataUrl:
        "https://akm-img-a-in.tosshub.com/sites/elections/2024/assembly/json/state-master/maharashtra-2019-all-consituency.json",
      2015: "https://akm-img-a-in.tosshub.com/sites/elections/2024/assembly/json/state-master/delhi-2015-all-consituency.json",
      2020: "https://akm-img-a-in.tosshub.com/sites/elections/2024/assembly/json/state-master/delhi-2020-all-consituency.json",
      2025: "https://electionresults.intoday.in/elections/2025/assembly-elections/json/state/delhi-all-consituency.json",

      // 2014: "https://akm-img-a-in.tosshub.com/sites/elections/2024/assembly/json/state-master/jharkhand-2014-all-consituency.json",
      // 2019: "https://akm-img-a-in.tosshub.com/sites/elections/2024/assembly/json/state-master/jharkhand-2019-all-consituency.json",
      // 2024: "https://electionresults.intoday.in/elections/2024/assembly-elections/json/state/jharkhand-all-consituency.json",
      // "2024" : "https://electionresults.intoday.in/elections/2024/assembly-elections/json/state/haryana-all-consituency.json",
      constituency: {
        //this is used in url
        state: "Delhi",
        stateHindi: "दिल्ली",
        url3Key: "ConstituencyDetailURL",
        prevYear: [2020, 2015],
        // url: "https://akm-img-a-in.tosshub.com/sites/elections/2024/assembly/json/constituency/delhi/",
        url: "https://akm-img-a-in.tosshub.com/sites/elections/2024/assembly/json/constituency/delhi/",
        // "url3": "https://electionresults.intoday.in/elections/2024/assembly-elections/json/constituency-alpha/",
        url3: "https://electionresults.intoday.in/elections/2025/assembly-elections/json/constituency-tak/",
        url2: "https://akm-img-a-in.tosshub.com/sites/elections/2024/assembly/json/state-master/delhi-state-master.json",
        tabs: [],
      },
    },
    tallyState: "delhi",
    candidates : {
      candidate : {
        state: "Delhi",
      stateHindi: "दिल्ली",
      dateKey: "ConstituencyDetailURL",

        dataUrl : 'https://electionresults.intoday.in/elections/2025/assembly-elections/json/constituency-tak',
        constNameKey : "Constituency_field_Profile_Page",
        
      }
    }
  },



  //assembly chunav
  "assembly-chunav": {
    tallyState: "maharashtra",
    breadCrump: [
      {
        displayText: "कौन जीता-कौन हारा",
        url: "/election/delhi-assembly-chunav/results",
        keyToShow: "Delhi_KaunJeeta",
      },
      {
        displayText: "प्रमुख उम्मीदवार",
        url: "/election/delhi-assembly-chunav/key-candidates",
        keyToShow: "DelhiKeyCandidate",
      },
      {
        displayText: "एग्जिट पोल",
        url: "/election/delhi-assembly-chunav/exit-poll",
        keyToShow: "DelhiExitPollPage",
      },
      {
        displayText: "विधान सभा रिजल्ट",
        url: "/election/delhi-assembly-chunav/party-wise-result",
        keyToShow: "DelhiResultPage",
      },
      {
        displayText: "शेड्यूल",
        url: "/election/delhi-assembly-chunav/schedule",
        keyToShow: "DelhiSchedulePage",
      },
      {
        displayText: "सामान्य प्रश्न",
        url: "/election/assembly-chunav/faq",
        keyToShow: "",
      },
    ],
    description : 'इस बार के दिल्ली विधानसभा चुनाव में कौन मारेगा बाजी? आम आदमी पार्टी (AAP) लगातार चौथी बार बनाएगी सरकार या भारतीय जनता पार्टी (BJP) को मिलेगा जनता का साथ या कांग्रेस (Congress) कर सकती है कमाल? मतदाता सूची में आपका नाम जुड़ा है या नहीं, ये कैसे पता करेंगे? अगर वोटर आईडी कार्ड नहीं बना है तो क्या फिर भी आप अपना वोट डाल सकते हैं? ऐसे कई सवाल हैं जो इन दो राज्यों के मतदाताओं के मन में उठ रहे हैं. वोटर्स की सुविधा के लिए हम ऐसे ही कुछ सवालों के जवाब यहां दे रहे हैं.',
    "masterConfigKey": "Delhi_vidhansabha_election"
  }
    ,
  "home-page" : {
    "masterConfigKey": "Delhi_vidhansabha_election"
  }

}



// Duplicate Candidates:
// Ghonda Constituency: 30066
// Gaurav Sharma: candidate_id: 656 & 1833
// Janakpuri Constituecy: 30030
// Ashish Sood: candidate_id: 350 & 1832
// Janakpuri Constituecy: 30030
// Praveen Kumar: candidate_id: 136 & 1834